<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { email, minLength, required } from "vuelidate/lib/validators";
// Import HTTP
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
//Common HTTP object for managing request response
let httpRequest = new HttpRequest();
let preference = new Preference();
// Import component
import Loading from "vue-loading-overlay";

// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading);
import { bus } from "@/views/pages/dashboard/index";

export default {
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      oauth_verifier: null,
      isLoading: false,
      orgOwnerEmail: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      name: "",
      designation: "",
      email: "",
      avatar_url: "",
      avatar: "",
      company: "",
      password: "",
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    password: { minLength: minLength(8) },
  },
  components: {
    Layout,
    PageHeader,
  },

  methods: {
    getUserProfileData() {
      let _this = this;
      _this.isLoading = true;
      return httpRequest.getMyProfileData().then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          _this.name = response.data.name;
          _this.email = response.data.email;
          _this.company = response.data.company_name;
          _this.avatar_url = response.data.image;
        }
      });
    },
    onChange(e) {
      const file = e.target.files[0];
      this.avatar = file;
      this.avatar_url = URL.createObjectURL(file);
    },

    updateProfileData() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        // Init loader
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: "#ffffff",
          loader: "dots",
        });

        // Init sweet alert
        let swal = this.$swal;

        httpRequest
          .updateProfileData(this.name, this.password, this.avatar)
          .then(function (response) {
            loader.hide();
            if (response.success) {
              if (response.data.name) {
                bus.$emit("user_name", response.data.name);
                preference.setData(Preference.KEY_NAME, response.data.name);
              }
              if (response.data.image) {
                bus.$emit("user_image", response.data.image);
                preference.setData(Preference.KEY_IMAGE, response.data.image);
              }
              swal(response.message).then(function () {
                window.location = "/profile";
              });
            } else {
              swal(response.message);
            }
          });
      }
    },
  },
  async created() {
    await this.getUserProfileData();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="p-2 w-100" style="max-width: 500px">
              <form class="form-horizontal" @submit.prevent="updateProfileData">
                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-building-3-line auti-custom-input-icon customs-text-color"></i>
                  <label for="company">Company name</label>
                  <input type="text" readonly class="form-control" style="background-color: #eee" id="company"
                    v-model="company" />
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-mail-line auti-custom-input-icon customs-text-color"></i>
                  <label for="companyemail">Email</label>
                  <input type="text" readonly class="form-control" style="background-color: #eee" id="companyemail"
                    v-model="email" />
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-user-line auti-custom-input-icon customs-text-color"></i>
                  <label for="name">Full Name</label>
                  <input type="text" class="form-control" id="name"
                    :class="{ 'is-invalid': submitted && $v.name.$error }" placeholder="Enter name" v-model="name" />
                  <div v-if="submitted && !$v.name.required" class="invalid-feedback">
                    Name is required.
                  </div>
                </div>

                <div class="form-group auth-form-group-custom mb-3 mb-md-4">
                  <i class="ri-lock-2-line auti-custom-input-icon customs-text-color"></i>
                  <label for="password">Password</label>
                  <input type="password" class="form-control" id="password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }" placeholder="Reset password"
                    v-model="password" />
                  <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                    <span v-if="password && !$v.password.minLength">Password must be minimum 8 characters</span>
                  </div>
                </div>

                <div class="form-group">
                  <b-row class="display-center">
                    <b-col cols="4">
                      <b-img v-if="avatar_url" :src="avatar_url" height="80" alt="Responsive image"></b-img>
                    </b-col>
                    <b-col cols="8">
                      <b-form-file id="file-large" placeholder="Set Avatar" size="lg" type="file" accept=".jpg, .png"
                        @change="onChange"></b-form-file>
                    </b-col>
                  </b-row>
                </div>

                <div class="mt-5 mt-md-10 text-right">
                  <b-button class="customs-bg-color" variant="primary" size="lg" type="submit">SAVE
                    <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.selected_package {
  border: 1px solid #f2a503;
  color: #111;
  background-color: #eff2f7;
}

.display-center {
  display: flex;
  align-items: center;
}

.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-text-color {
  color: #ff7c47;
}
</style>



<style>
.swal2-styled.swal2-confirm {
  background-color: #ff7c47 !important;
}
</style>